import { useEffect, useState } from "react";

import { updateImageAndSponsoredAttributes } from "@/utilities/content-parser";

const Content = ({ content, customClass }) => {
  const parsedHtmlContent = updateImageAndSponsoredAttributes(content);

  const [clientContent, setClientContent] = useState(null);

  useEffect(() => {
    setClientContent(parsedHtmlContent);
  }, [content]);

  if (!clientContent) return null;

  return (
    <div
      id="bettingnews-content"
      className={customClass}
      dangerouslySetInnerHTML={{ __html: clientContent || content }}
      suppressHydrationWarning
    />
  );
};

export default Content;
