import Image from "next/image";
import Link from "next/link";

import useScreenWidth from "@/hooks/useScreenWidth";

const TopBannerImageHomepage = ({ banner }) => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 480;
  const bannerIsImage = banner.bannerInfo.imageOrVideo === "image";

  return (
    <>
      {banner && bannerIsImage ? (
        <div>
          <Link
            title={banner?.bannerInfo?.title}
            aria-label={banner?.bannerInfo?.title}
            href={banner?.bannerInfo?.link ?? ""}
            target={banner?.title === "banner-homepage" ? "_blank" : ""}
            data-event-label="seasonal-banner"
          >
            <Image
              className="mb-6 h-auto w-full rounded-lg"
              src={banner?.bannerInfo?.src}
              alt={banner?.bannerInfo?.title}
              width={isMobile ? 300 : 900}
              loading="eager"
              height={100}
              sizes="(max-width: 480px) 300px, 900px"
              quality={65}
            />
          </Link>
        </div>
      ) : (
        <div className="video-container">
          <div
            dangerouslySetInnerHTML={{
              __html: banner?.bannerInfo?.videoEmbed || "",
            }}
          />
        </div>
      )}
    </>
  );
};

export default TopBannerImageHomepage;
